module.exports={
  "_args": [
    [
      "elliptic@6.4.1",
      "/home/greg/code/github.com/ethereum/web3.js"
    ]
  ],
  "_from": "elliptic@6.4.1",
  "_id": "elliptic@6.4.1",
  "_inBundle": false,
  "_integrity": "sha512-BsXLz5sqX8OHcsh7CqBMztyXARmGQ3LWPtGjJi6DiJHq5C/qvi9P3OqgswKSDftbu8+IoI/QDTAm2fFnQ9SZSQ==",
  "_location": "/elliptic",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "elliptic@6.4.1",
    "name": "elliptic",
    "escapedName": "elliptic",
    "rawSpec": "6.4.1",
    "saveSpec": null,
    "fetchSpec": "6.4.1"
  },
  "_requiredBy": [
    "/browserify-sign",
    "/create-ecdh",
    "/eth-lib",
    "/secp256k1",
    "/swarm-js/eth-lib",
    "/web3-core-helpers/eth-lib",
    "/web3-core-method/eth-lib",
    "/web3-core/eth-lib",
    "/web3-eth-accounts/eth-lib",
    "/web3-eth-accounts/web3-utils/eth-lib",
    "/web3-eth-contract/eth-lib",
    "/web3-eth-ens/eth-lib",
    "/web3-eth-iban/eth-lib",
    "/web3-eth-personal/eth-lib",
    "/web3-eth/eth-lib",
    "/web3-net/eth-lib",
    "/web3-utils/eth-lib",
    "/web3/eth-lib"
  ],
  "_resolved": "https://registry.npmjs.org/elliptic/-/elliptic-6.4.1.tgz",
  "_spec": "6.4.1",
  "_where": "/home/greg/code/github.com/ethereum/web3.js",
  "author": {
    "name": "Fedor Indutny",
    "email": "fedor@indutny.com"
  },
  "bugs": {
    "url": "https://github.com/indutny/elliptic/issues"
  },
  "dependencies": {
    "bn.js": "^4.4.0",
    "brorand": "^1.0.1",
    "hash.js": "^1.0.0",
    "hmac-drbg": "^1.0.0",
    "inherits": "^2.0.1",
    "minimalistic-assert": "^1.0.0",
    "minimalistic-crypto-utils": "^1.0.0"
  },
  "description": "EC cryptography",
  "devDependencies": {
    "brfs": "^1.4.3",
    "coveralls": "^2.11.3",
    "grunt": "^0.4.5",
    "grunt-browserify": "^5.0.0",
    "grunt-cli": "^1.2.0",
    "grunt-contrib-connect": "^1.0.0",
    "grunt-contrib-copy": "^1.0.0",
    "grunt-contrib-uglify": "^1.0.1",
    "grunt-mocha-istanbul": "^3.0.1",
    "grunt-saucelabs": "^8.6.2",
    "istanbul": "^0.4.2",
    "jscs": "^2.9.0",
    "jshint": "^2.6.0",
    "mocha": "^2.1.0"
  },
  "files": [
    "lib"
  ],
  "homepage": "https://github.com/indutny/elliptic",
  "keywords": [
    "EC",
    "Elliptic",
    "curve",
    "Cryptography"
  ],
  "license": "MIT",
  "main": "lib/elliptic.js",
  "name": "elliptic",
  "repository": {
    "type": "git",
    "url": "git+ssh://git@github.com/indutny/elliptic.git"
  },
  "scripts": {
    "jscs": "jscs benchmarks/*.js lib/*.js lib/**/*.js lib/**/**/*.js test/index.js",
    "jshint": "jscs benchmarks/*.js lib/*.js lib/**/*.js lib/**/**/*.js test/index.js",
    "lint": "npm run jscs && npm run jshint",
    "test": "npm run lint && npm run unit",
    "unit": "istanbul test _mocha --reporter=spec test/index.js",
    "version": "grunt dist && git add dist/"
  },
  "version": "6.4.1"
}
