"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var abstract_coder_1 = require("./abstract-coder");
var array_1 = require("./array");
var TupleCoder = /** @class */ (function (_super) {
    __extends(TupleCoder, _super);
    function TupleCoder(coders, localName) {
        var _this = this;
        var dynamic = false;
        var types = [];
        coders.forEach(function (coder) {
            if (coder.dynamic) {
                dynamic = true;
            }
            types.push(coder.type);
        });
        var type = ("tuple(" + types.join(",") + ")");
        _this = _super.call(this, "tuple", type, localName, dynamic) || this;
        _this.coders = coders;
        return _this;
    }
    TupleCoder.prototype.encode = function (writer, value) {
        return array_1.pack(writer, this.coders, value);
    };
    TupleCoder.prototype.decode = function (reader) {
        return reader.coerce(this.name, array_1.unpack(reader, this.coders));
    };
    return TupleCoder;
}(abstract_coder_1.Coder));
exports.TupleCoder = TupleCoder;
