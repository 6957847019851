module.exports={
    "name": "ropsten",
    "chainId": 3,
    "networkId": 3,
    "comment": "PoW test network",
    "url": "https://github.com/ethereum/ropsten",
    "genesis": {
        "hash": "0x41941023680923e0fe4d74a34bdac8141f2540e3ae90623718e47d66d1ca4a2d",
        "timestamp": null,
        "gasLimit": 16777216,
        "difficulty": 1048576,
        "nonce": "0x0000000000000042",
        "extraData": "0x3535353535353535353535353535353535353535353535353535353535353535",
        "stateRoot": "0x217b0bbcfb72e2d57e28f33cb361b9983513177755dc3f33ce3e7022ed62b77b"
    },
    "hardforks": [
        {
            "name": "chainstart",
            "block": 0,
            "consensus": "pow",
            "finality": null
        },
        {
            "name": "homestead",
            "block": 0,
            "consensus": "pow",
            "finality": null
        },
        {
            "name": "dao",
            "block": null,
            "consensus": "pow",
            "finality": null
        },
        {
            "name": "tangerineWhistle",
            "block": 0,
            "consensus": "pow",
            "finality": null
        },
        {
            "name": "spuriousDragon",
            "block": 10,
            "consensus": "pow",
            "finality": null
        },
        {
            "name": "byzantium",
            "block": 1700000,
            "consensus": "pow",
            "finality": null
        },
        {
            "name": "constantinople",
            "block": 4230000,
            "consensus": "pow",
            "finality": null
        },
        {
            "name": "petersburg",
            "block": 4939394,
            "consensus": "pow",
            "finality": null
        },
        {
            "name": "istanbul",
            "block": null,
            "consensus": "pow",
            "finality": null
        }
    ],
    "bootstrapNodes": [
        {
            "ip": "52.176.7.10",
            "port": "30303",
            "id": "30b7ab30a01c124a6cceca36863ece12c4f5fa68e3ba9b0b51407ccc002eeed3b3102d20a88f1c1d3c3154e2449317b8ef95090e77b312d5cc39354f86d5d606",
            "network": "Ropsten",
            "chainId": 3,
            "location": "US",
            "comment": "US-Azure geth"
        },
        {
            "ip": "52.176.100.77",
            "port": "30303",
            "id": "865a63255b3bb68023b6bffd5095118fcc13e79dcf014fe4e47e065c350c7cc72af2e53eff895f11ba1bbb6a2b33271c1116ee870f266618eadfc2e78aa7349c",
            "network": "Ropsten",
            "chainId": 3,
            "location": "US",
            "comment": "US-Azure parity"
        },
        {
            "ip": "52.232.243.152",
            "port": "30303",
            "id": "6332792c4a00e3e4ee0926ed89e0d27ef985424d97b6a45bf0f23e51f0dcb5e66b875777506458aea7af6f9e4ffb69f43f3778ee73c81ed9d34c51c4b16b0b0f",
            "network": "Ropsten",
            "chainId": 3,
            "location": "US",
            "comment": "Parity"
        },
        {
            "ip": "192.81.208.223",
            "port": "30303",
            "id": "94c15d1b9e2fe7ce56e458b9a3b672ef11894ddedd0c6f247e0f1d3487f52b66208fb4aeb8179fce6e3a749ea93ed147c37976d67af557508d199d9594c35f09",
            "network": "Ropsten",
            "chainId": 3,
            "location": "US",
            "comment": "@gpip"
        }
    ]
}
